import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('header-component')
export class HeaderComponent extends LitElement {

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div class="flex flex-row items-center space-x-4">
        <img src="logo.svg" alt="AppShovel Logo" class="w-16 h-16"/>
        <a href="/" class="text-5xl font-bold relative">AppShovel</a>
      </div>
      <div class="divider"></div>
    `;
  }
}
