import { LitElement, html, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';

@customElement('rating-component')
export class RatingComponent extends LitElement {
  @property({ type: Number }) value: number = 0; // Current rating value

  static styles = css`
    .rating {
      display: flex;
      font-size: 20px;
      gap: 2px; /* Small gap between stars */
    }
    .star {
      width: 20px;
      height: 20px;
      background-color: lightgray;
      mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon fill="black" points="12,2 15,8.7 22,9.3 17,14 18,21 12,17.5 6,21 7,14 2,9.3 9,8.7"></polygon></svg>') center / contain no-repeat;
      -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon fill="black" points="12,2 15,8.7 22,9.3 17,14 18,21 12,17.5 6,21 7,14 2,9.3 9,8.7"></polygon></svg>') center / contain no-repeat;
    }
    .star.filled {
      background-color: gold;
    }
    .star.partial {
      background: linear-gradient(
        to right,
        gold calc(var(--fill-percent) * 100%),
        lightgray calc(var(--fill-percent) * 100%)
      );
    }
  `;

  render() {
    return html`
      <div class="rating">
        ${Array.from({ length: 5 }, (_, i) => {
          const starIndex = i + 1;
          const fillPercent = Math.max(0, Math.min(1, this.value - (starIndex - 1)));
          return html`
            <div
              class="star ${fillPercent >= 1 ? 'filled' : fillPercent > 0 ? 'partial' : ''}"
              style="--fill-percent: ${fillPercent};"
            ></div>
          `;
        })}
      </div>
    `;
  }
}