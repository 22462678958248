import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Api } from "../api.js";

import '../components/header-component.ts';

@customElement("main-page")
export class MainPage extends BaseElement {
    @state() mail = "";
    @state() isMailValid = false;
    @state() isMailSignedUp = false;
    @state() loading = false;

    validateEmail(email: string): boolean {
        // Simple regex for email validation
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    }

    handleInput(e: InputEvent) {
        this.mail = (e.target as HTMLInputElement).value;
        this.isMailValid = this.validateEmail(this.mail);
    }

    async subscribeToNewsletter() {
        this.loading = true;
        try {
            const result = await Api.newsletterSubscribe(this.mail);
            if (result instanceof Error) {
                console.error("Error fetching keyword", result.message);
                return;
            }
            this.isMailSignedUp = result.success;
        } catch (error) {
            console.error("Error fetching keyword:", error);
            alert("Failed to fetch keyword. Please try again.");
        } finally {
            this.loading = false; // Reset loading state
        }
    }

    render() { return html`
    <div class="container max-w-5xl p-4 md:p-6 lg:p8">
        
        <header-component></header-component>
        
        <p class="text-xl mb-3 text-green pb-4">Find keywords that rank on the Apple AppStore and get more organic downloads.</p>
        
        <img src="hero.webp" alt="AppShovel Keyword Search"/>
        
        <div class="divider"></div>
        
        <h2>Get Notified</h2>
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
            <input class="input input-bordered w-full"
                type="email"
                placeholder="Your email"
                .value=${this.mail}
                @input=${(e: InputEvent) => this.handleInput(e)}/>
            
            <button class="btn btn-primary w-full md:max-w-32 plausible-event-name=newsletter ${!this.isMailValid || this.loading ? 'opacity-50 cursor-not-allowed' : ''}"
                @click=${this.subscribeToNewsletter}
                ?disabled=${!this.isMailValid}>Sign Up</button>
        </div>

        <p class="text-xl mb-3 text-green pt-6">${!this.isMailSignedUp ? 'Stay up to date and get early access.' : 'Thank you! You will get notfied when AppShovel will launch.'}</p>
        
        <div class="divider"></div>

        <h2>FAQ</h2>
        <h3>What is App Store Optimization?</h3>
        <p>App Store Optimization (ASO) is the process of improving an app’s visibility in app stores like the Apple App Store or Google Play Store. It involves optimizing elements like the app title, description, keywords, visuals, and user ratings to drive more organic downloads.</p>
        <p>Keywords are essential in App Store Optimization because they determine how easily users can find your app in search results. By strategically selecting and optimizing keywords, you increase your app’s visibility for relevant searches, leading to more organic downloads and better ranking within the app store.</p>
        </br>
        <h3>How does AppShovel get keyword popularity?</h3>
        <p>AppShovel fetches keyword popularity data directly from Apple Search Ads, which is Apple’s advertising platform for promoting apps in the App Store. Apple Search Ads provides a keyword popularity score that reflects how often a specific keyword is searched by users in the App Store.</p>
        </br>
        <h3>How can I use AppShovel?</h3>
        <p>In order to search for keyword popularity, you need to enter your Apple ID and and <a target=_blank href="https://support.apple.com/en-us/102654">app-specific password</a>. You can also create a new Apple ID if you don't want to use your main account.<p>
        <p>You also need to setup an <a href="">Apple SearchAds Account</a> and connect at least one campaign group to your App Store Connect account.</p>
        </br>

        <div class="divider"></div>

        <footer>
            <a href="/imprint">Imprint</a>
        </footer>

    </div>
    `;
    }
}
