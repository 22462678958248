import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Store, User } from "../utils/store.js";

@customElement("settings-page")
export class SettingsPage extends BaseElement {
    @state()
    private user: User = Store.getUser();

    private saveUserSettings() {
        Store.setUser(this.user);
        alert("User settings saved!");
    }

    render() {
        return html`
        <div class="container max-w-5xl p-4 md:p-6 lg:p8">

            <header-component></header-component>

            <h1>Settings</h1>
            </br>
            <h2>AppStore Connect Account</h2>
            <form class="flex flex-col space-y-6"
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.saveUserSettings();
                }}
            >
                <div class="flex flex-col space-y-2">
                    <p>Apple ID</p>
                    <input class="input input-bordered w-full max-w-xs" type="text"
                        .value=${this.user.aaplUser}
                        @input=${(e: Event) =>
                            (this.user = { ...this.user, aaplUser: (e.target as HTMLInputElement).value })}
                    />
                </div>
                
                <div class="flex flex-col space-y-2">
                    <p>App Specific Password</p>
                    <input class="input input-bordered w-full max-w-xs" type="password"
                        .value=${this.user.aaplPass}
                        @input=${(e: Event) =>
                            (this.user = { ...this.user, aaplPass: (e.target as HTMLInputElement).value })}
                    />
                </div>
                <button class="btn btn-primary w-full max-w-xs" type="submit">Save</button>
            </form>
            <div class="divider"></div>
            <p>In order to search for keyword popularity, you need to enter your Apple ID and and <a target=_blank href="https://support.apple.com/en-us/102654" class="font-medium text-blue-500">app-specific password</a>. You can also create a new Apple ID if you don't want to use your main account.<p>
            <p>You also need to setup an <a target=_blank href="https://searchads.apple.com" class="font-medium text-blue-500">Apple SearchAds Account</a> and connect at least one campaign group to your App Store Connect account.</p>
        <div>
        `;
    }
}
