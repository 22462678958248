import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";

import '../components/header-component.ts';
import { Api } from "../api.js";
import { User } from "../model/user.js";

// Declare the Paddle interface globally
declare const Paddle: any;

@customElement("pricing-page")
export class PricingPage extends BaseElement {
  @state() user?: User;
  @state() price: number = 5; // Set default price

  constructor() {
    super();
    const script = document.createElement("script");
    script.src = "https://sandbox-cdn.paddle.com/paddle/v2/paddle.js";
    script.onload = () => {
      Paddle.Environment.set("sandbox");
      Paddle.Initialize({ 
        token: "test_bcd53cbb087424af9857f7c0ddf"
      });
    };
    document.head.appendChild(script);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.fetchUser();
  }

  async fetchUser(): Promise<void> {
    try {
      const result = await Api.user();
        if (result instanceof Error) {
            throw result;
        }
        this.user = result;
    } catch (error) {
      console.error("Error fetching user data:", error);
      window.location.href = "/login";
    }
  }

  // Simulate subscription action
  checkout(): void {
    const user = this.user;
    if (!user) {
      return;
    }
    const priceId = "pri_01jhqttp75jdjvaaw0zrvyfek5";
    const successUrl = `${window.location.protocol}//${window.location.host}/search`;
    Paddle.Checkout.open({
      settings: {
        successUrl: successUrl
      },
      items: [
        {
          priceId: priceId
        },
      ],
      customer: {
        email: user.email
      },
      customData: {
        user_id: user.id,
      }
    });
  }

  render() {
    return html`
      <div class="container max-w-5xl p-4 md:p-6 lg:p-8 my-6">

        <header-component></header-component>
        
        <div class="flex flex-col mx-auto space-y-4 max-w-lg p-6 rounded-3xl border border-gray-200">
          <h3 class="text-5xl font-bold">Explore</h3>
          
          <p class="font-light text-gray-500 sm:text-lg">Discover popular keywords on the AppStore.</p>
          
          <div class="flex items-baseline">
            <span class="mr-2 text-6xl font-extrabold">$${this.price}</span>
            <span class="text-gray-500">/month</span>
          </div>

          <!-- List -->

          <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Keyword <span class="font-semibold">popularity</span> and <span class="font-semibold">difficulty</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Keyword <span class="font-semibold">suggestions</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>AppStore <span class="font-semibold">rankings</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Title & subtitle <span class="font-semibold">opportunities</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Search <span class="font-semibold">60 countries</span></span>
              </li>
          </ul>

          <button
            class="btn btn-primary w-full"
            @click=${this.checkout}
          >
            Get Started
          </button>

          
        </div>

        <div class="divider"></div>

        <footer>
          <a href="/imprint">Imprint</a>
        </footer>
      </div>
    `;
  }
}
