import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Api } from "../api.js";

import '../components/header-component.ts';

@customElement("login-page")
export class LoginPage extends BaseElement {
    @state() mail = "";
    @state() sentMagicLink = false;
    @state() isMailSignedUp = false;
    @state() loading = false;

    validateEmail(email: string): boolean {
        // Simple regex for email validation
        const emailRegex = /^[\w+.-]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    }

    handleInput(e: InputEvent) {
        this.mail = (e.target as HTMLInputElement).value;
        this.sentMagicLink = this.validateEmail(this.mail);
    }

    async sendMagicLink() {
        this.loading = true;
        try {
            const result = await Api.magicLink(this.mail);
            if (result instanceof Error) {
                console.error("Error creating magic link.", result.message);
                return;
            }
            this.isMailSignedUp = true;
        } catch (error) {
            console.error("Error fetching keyword:", error);
            alert("Failed to fetch keyword. Please try again.");
        } finally {
            this.loading = false; // Reset loading state
        }
    }

    render() { return html`
    <div class="container max-w-5xl p-4 md:p-6 lg:p8">
        
        <header-component></header-component>
        
        <h1>Login</h1>

        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
            <input class="input input-bordered w-full"
                type="email"
                placeholder="Your email"
                .value=${this.mail}
                @input=${(e: InputEvent) => this.handleInput(e)}/>
            
            <button class="btn btn-primary w-full md:max-w-32 ${!this.sentMagicLink || this.loading ? 'opacity-50 cursor-not-allowed' : ''}"
                @click=${this.sendMagicLink}
                ?disabled=${!this.sentMagicLink}>Get Started</button>
        </div>

        <p class="text-xl mb-3 text-green pt-6">${!this.isMailSignedUp ? 'Enter your email to receive a magic login link.' : 'Check your email for the magic link.'}</p>
        
        <div class="divider"></div>

        <footer>
            <a href="/imprint">Imprint</a>
        </footer>

    </div>
    `;
    }
}
