import { LitElement, PropertyValueMap, html } from "lit";
import { customElement } from "lit/decorators.js";
import { i18n } from "./utils/i18n.js";
import { setupLiveReload } from "./utils/live-reload.js";
import { router } from "./utils/routing.js";
export * from "./pages/index.js";
export * from "./utils/ui-components.js";

setupLiveReload();

@customElement("app-main")
export class App extends LitElement {
    constructor() {
        super();
    }

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        
        router.addRoute(
            "/",
            () => html`<main-page></main-page>`,
            () => "AppShovel"
        );
        router.addRoute(
            "/pricing",
            () => html`<pricing-page></pricing-page>`,
            () => "AppShovel | Pricing"
        );
        router.addRoute(
            "/404",
            () => html`${i18n("Whoops, that page doesn't exist")}`,
            () => "404"
        );
        router.addRoute(
            "/imprint",
            () => html`<imprint-page></imprint-page>`,
            () => "AppShovel | Imprint"
        );
        router.addRoute(
            "/login",
            () => html`<login-page></login-page>`,
            () => "AppShovel | Login"
        );
        router.addRoute(
            "/search",
            () => html`<search-page></search-page>`,
            () => "AppShovel | Search"
        );
        router.addRoute(
            "/settings",
            () => html`<settings-page></settings-page>`,
            () => "AppShovel | Settings"
        );

        router.setRootRoute("/");
        router.setNotFoundRoot("/404");
        router.replace(location.pathname);
    }
}
